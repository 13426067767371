
@import '~antd/dist/antd.css';
.space-align-block {
    flex: none;
    margin: 8px 4px;
    padding: 4px;
  }
  .space-align-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .taggedInfo{
      margin-top: 10px;
      font-size: 15px;
  }

  .site-layout-card .ant-card-body{
    padding-bottom: 6px!important;
  }

  [class="ant-tabs-tab"] a{
    color:black;
  }
  [class="ant-tabs-tab"] a:hover{
    color:#40a9ff;
  }
  .spider>div{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
.profile-row{
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
.userData{
  text-align: center;
}
.userProfile{
  display: none;
}
.site-layout-card .ant-card-body{
  padding: 0;
  margin: 0px!important;
}
.site-layout-card{
  margin: 0px 0px!important;
  margin-top: 20px!important; 
  margin-bottom: 10px!important; 
}
.profile-tabs .ant-tabs-nav{
  margin: 0;
}
.profile-tabs{
  padding-right: 20px;
}
.profile-row{
  margin-top: 0px;
}
  }