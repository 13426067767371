
@import '~antd/dist/antd.css';
body{
  background-color: #F3F4F9;
}
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  float: left;
  transition: color 0.3s;
}

.trigger:hover {
  color: #0972B6;
}

.logo {
  float: right;
  width: 100px;
  height: 70px;
  background-image: url('assets/images/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.site-layout .site-layout-background {
  background: #fff;
}
.menuIcon{
 text-align: right;
}
.ant-layout-header{
  background-color: #fff;
}
.loginParent{
  text-align:right;
  font-family:'jannatR';
  background-color:#F3F4F9;
  padding:8% 10% 0;
}
.loginBox{
  padding:100px 60px 0;
  border-radius:10px;
  box-shadow:0 2px 6px 0 hsla(0,0%,0%,0.2);
  height:500px;
  background-color: #fff;
}
.loginBox>div{
  margin: 0 50px;
}
.formTitle{
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  color: #0972B6;
}
.formTitle img{
  width: 150px;
}
.loginBox input{
  background-color:#ADD0E6;
}
#root{
  height: 100%;
}
.login-btn{
margin-top: 40px;
}
.loginForm{
margin-top: 50px;
}
.illustrateBox{
  display: none;
}
@media only screen and (max-width: 600px) {
  .mainColumn{
    display: none;
  }
  .loginBox{
    padding:10px 30px 20px;
    height: auto;
  }
  .formTitle{

    margin-bottom: 10px;
    margin-top: 10px;
  }
  .formTitle img{
    width: 120px;
  }

  .loginBox>div{
    margin: 0;
  }
  .formColumn{
    max-width: 100%;
    flex: none;
  }
  .formColumn .ant-form-item-label{
    text-align: right;
    padding-bottom: 0px;
  }
  .formColumn .ant-form-item{
    margin-bottom: 0px;
  }
  .login-btn{
    margin-top: 20px;
    }
    .loginForm{
      margin-top: 0px;
      }
      .illustrateBox{
        display: block;
        padding: 20px 10px;
      }
      .loginParent{
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
}