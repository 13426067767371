.danger{
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.warning{
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
}
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@500&display=swap');
.attendance .ant-table-cell,.att-model .ant-table-cell{
    text-align: center!important;
    font-weight: 700;
}
.table-info{
    display: table-header-group;
}
div.header{
position: fixed;
top:0;
}
@media print{
    #att-report h1,h2{
        margin: 0;
    }
    #att-report thead tr{
    
        color:#fff;background-color: #0972B6;height: 30px;
    }
    #att-report tbody tr:nth-child(even){
        background-color: #e6e6e6;
    }
    #att-report th{
        font-weight: 100;
    }
    #att-report caption{
        font-weight: 900;
    }
    .table-info{
        position: fixed;
    }
}
.attOper{
    float:left;
    margin-bottom:10px;
    display: flex;
    flex-direction: row;
}
.attHeader{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-bottom:30px;
}
.attPer{
    display:flex;
    flex-direction:row;
}
.disPer{
    display:flex;
    flex-direction:row;  
}
@media only screen and (max-width: 600px) {

.attOper{
    order:1;
    display: flex;
    flex-direction: column;
}


.attOperBtn{
    padding-top: 14px;
}
.attHeader{
    flex-direction:column;
}
.attPer{
order: 2;
margin-bottom: 15px;
}
.attOperRange{
   
    margin-top: 15px;
}
.disPer{
    order: 3; 
}
}

@page { size: A4 landscape; print-color-adjust: exact !important;  -webkit-print-color-adjust: exact !important;}